import React, { useContext, useEffect, useMemo, useState } from "react";
import { useUser } from "../../hooks/useUser";
import { RxCross2 } from "react-icons/rx";
import { useWebSocket } from "../../hooks/useWebsocket";
import { DataContext } from "../../contexts/DataContext";
import { NotifyContext } from "../../contexts/NotifyContext";

interface DateUnlockType {
  hours: number;
  minutes: number;
}

interface DailyRewardProps {
  dateUnlock: DateUnlockType;
  showCalendar: boolean;
  setShowCalendar: (value: boolean) => void;
}
const DailyReward = ({
  dateUnlock,
  showCalendar,
  setShowCalendar,
}: DailyRewardProps) => {
  const [weeklyClaimedDays, setWeeklyClaimedDays] = useState(0);
  const context = useContext(DataContext);
  const { webSocket } = useWebSocket();
  const notifyContext = useContext(NotifyContext);
  const { user } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [canClaim, setCanClaim] = useState(false);
  const initialReward = context?.settings?.dailyReward || 50;
  const formatReward = (value: number) => {
    if (value >= 1000) {
      return (value / 1000).toFixed(1).replace(/\.0$/, "") + "k";
    }
    return value.toString();
  };
  const rewardData = Array.from({ length: 7 }, (_, index) => ({
    day: index + 1,
    reward: formatReward(initialReward * Math.pow(2, index)),
  }));

  const dailyDisabled = useMemo(() => {
    if (user) {
      return Date.now() - user.lastDailyRewardTimestamp < 1000 * 60 * 60 * 24;
    } else {
      return true;
    }
  }, [user]);

  useEffect(() => {
    if (webSocket) {
      // webSocket.on("boostActivated", (msg) => {
      webSocket.on("boostActivatedDailyReward", (msg) => {
        notifyContext?.setNotify({
          status: "ok",
          message: msg.message,
          className: "h-96",
        });
        setIsLoading(false);
        fetchDailyRewardDetails();
        setTimeout(() => {
          setShowCalendar(false);
        }, 1000);
      });
    }

    return () => {
      // webSocket?.off("boostActivated");
      webSocket?.off("boostActivatedDailyReward");
    };
  }, [webSocket]);

  const onDailyActivate = () => {
    if (webSocket && !dailyDisabled) {
      setIsLoading(true);
      webSocket.emit(
        "claimDailyReward",
        // "activateBoost",
        JSON.stringify([user?.tgId, "dailyReward"])
      );
    } else {
      notifyContext?.setNotify({
        status: "error",
        message: "You already used this today",
        className: "h-96",
      });
    }
  };

  const fetchDailyRewardDetails = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_ADMIN_API_URL}/users/reward/${user?.id}`
      );
      const res = await response.json();
      if (res.status === 200) {
        const dayInMilliSeconds = 1000 * 60 * 60 * 24;

        if (
          res?.rewards?.lastClaimedTimestamp + dayInMilliSeconds <
          Date.now()
        ) {
          setWeeklyClaimedDays(res?.rewards?.weeklyClaimedDays);
          setShowCalendar(true);
          setCanClaim(true);
        } else {
          setWeeklyClaimedDays(res?.rewards?.weeklyClaimedDays);
          setCanClaim(false);
        }
      } else {
        setShowCalendar(true);
        setWeeklyClaimedDays(0);
        setCanClaim(true);
      }
    } catch (error) {
      console.error("Error fetching reward details:", error);
    }
  };
  useEffect(() => {
    fetchDailyRewardDetails();
  }, []);

  return (
    <>
      {showCalendar ? (
        <div className="fixed bottom-0 left-0 w-full bg-[#232730] h-[80%] rounded-tl-3xl rounded-tr-3xl z-50">
          <div className="relative flex flex-col items-center justify-center pt-10">
            <div
              className="absolute top-2 right-5 bg-[#414B60] p-2 rounded-full cursor-pointer"
              onClick={() => setShowCalendar(false)}
            >
              <RxCross2 />
            </div>
            <img
              className="w-[100px]"
              src="./img/calendar.png"
              alt="Calendar"
            />
            <div>
              <h1 className="text-white text-center uppercase font-bold mt-3">
                Daily Rewards
              </h1>
              <p className="text-[#677496] mt-2 text-center text-xs px-6">
                Accrue BILLIES for logging into the game daily without skipping
              </p>
            </div>
          </div>

          <div className="flex items-center justify-center mt-3 px-5 place-items-center place-content-center gap-y-5 gap-x-5 flex-wrap">
            {rewardData.map((item) => (
              <div
                key={item.day}
                className={`w-[20%] flex flex-col items-center justify-center gap-y-1 rounded-md px-3 py-1.5 border-2 ${item.day <= weeklyClaimedDays
                  ? "bg-[#2B5737] border-[#53FF82]"
                  : "bg-[#2E3442] border-[#232730]"
                  }`}
              >
                <h1 className="font-semibold whitespace-nowrap text-[10px]">
                  DAY {item.day}
                </h1>
                <img src="/img/time_shard.webp" alt="Coin" className="size-[25px]" />
                <p className="text-[#E0AE10] font-semibold text-[10px]">
                  {item.reward}
                </p>
              </div>
            ))}
          </div>
          {canClaim ? (
            <button
              className="block bg-[#288CEE] text-white w-[85%] mx-auto py-2 mt-5 rounded-3xl text-sm"
              onClick={onDailyActivate}
            >
              {!isLoading ? (
                "CLAIM NOW"
              ) : (
                <div className="loader mx-auto"></div>
              )}
            </button>
          ) : (
            <p className="py-5 mt-5 text-white px-6 text-center">
              You will be able to claim you daily reward in {dateUnlock?.hours}{" "}
              {dateUnlock?.hours > 1 ? "hours" : "hour"} {dateUnlock?.minutes}{" "}
              {dateUnlock?.minutes > 1 ? "minutes" : "minute"}
            </p>
          )}
        </div>
      ) : null}
    </>
  );
};

export default DailyReward;

import React, { useState, useRef } from "react";
import { useClick } from "../../hooks/useClick";
import { useClickSound } from "../../hooks/useClickSound";
import NumberSign from "../../components/NumberSign";
import { useUser } from "../../hooks/useUser";
import { useSkeletonLoading } from "../../hooks/useSkeletonLoading";
import { ClickerSkeleton } from "./ClickerSkeleton";
import { League } from "../../components/League";
import { BoostButton } from "../../components/BoostButton";
import "./Clicker.css";
import { DailyOffer } from "../../components/DailyOffer";
import { formatNumber, formatNumberWithCommas } from "../../utils/formatNumber";
import { FaGear } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import { levelBaseImages } from "../../utils/levelBaseImages";

export const Clicker: React.FC = () => {
  const { handleClick, energyCount } = useClick();
  const { playSound } = useClickSound('/sounds/tap_sound.mp3');
  const imgRef = useRef<HTMLImageElement>(null);

  const [numberSignPositions, setNumberSignPositions] = useState<
    { x: number; y: number; id: number }[]
  >([]);
  const [numberSignId, setNumberSignId] = useState<number>(0);
  const [activeTouches, setActiveTouches] = useState<Set<number>>(new Set());
  const [imageClicked, setImageClicked] = useState<boolean>(false);
  const { user } = useUser();
  const isSkeletonLoading = useSkeletonLoading();
  const navigate = useNavigate();

  const handleTouchStart = (event: TouchEvent) => {
    const newTouches = new Set<number>(
      Array.from(event.touches, (touch) => touch.identifier)
    );
    const newTouchIdentifiers = Array.from(newTouches).filter(
      (identifier) => !activeTouches.has(identifier)
    );

    if (newTouchIdentifiers.length > 0) {
      playSound();
      const touchPositions = Array.from(event.touches)
        .filter((touch) => newTouchIdentifiers.includes(touch.identifier))
        .map((touch, index) => ({
          x: touch.clientX,
          y: touch.clientY,
          id: numberSignId + index + 1,
          timestamp: Date.now(),
        }));

      if (energyCount > 0) {
        setNumberSignId((prevId) => prevId + touchPositions.length);

        setImageClicked(true);
        setTimeout(() => setImageClicked(false), 50);

        setNumberSignPositions((prevPositions) => [
          ...prevPositions,
          ...touchPositions,
        ]);
      }

      touchPositions.forEach((position) => {
        if (user) {
          handleClick({
            user_id: user.tgId,
            position: { x: position.x, y: position.y },
            time_stamp: Date.now(),
          });
        }
      });

      setActiveTouches(newTouches);
    }
  };

  const handleTouchMove = (event: TouchEvent) => { };

  const handleTouchEnd = (event: TouchEvent) => {
    event.preventDefault();
    const remainingTouches = new Set<number>(
      Array.from(event.touches, (touch) => touch.identifier)
    );
    setActiveTouches(remainingTouches);
  };

  const handleAnimationEnd = (id: number) => {
    setNumberSignPositions((prevPositions) =>
      prevPositions.filter((position) => position.id !== id)
    );
  };

  const handleContextMenu = (
    event: React.MouseEvent<HTMLImageElement, MouseEvent>
  ) => {
    event.preventDefault();
  };
  return (
    <div
      className="h-[100vh] text-center relative flex flex-col items-center"
      onScroll={(e) => e.preventDefault()}
    >
      {isSkeletonLoading ? (
        <ClickerSkeleton />
      ) : (
        <section
          className="w-full h-full"
        >
          <div className="w-full px-2 pt-2"
          >
            {/* <div className="flex justify-start items-center w-full mb-3 gap-2 bg-[#333A4866] rounded-full pr-2"> */}
            <div className="w-full grid grid-cols-12 mb-3 gap-2 bg-[#333A4866] rounded-full pr-2">
              <div
                className="col-span-7 w-full grid grid-cols-6 items-center gap-0.5 px-2 border-r-2 border-[#4E556780] py-1"
                onClick={() => {
                  navigate("/league");
                }}
              >
                <div className="col-span-2 rounded-full w-12 h-12 flex items-center justify-center overflow-hidden bg-gray-200 bg-opacity-50">
                  {/* {user?.firstName[0]} */}
                  {user &&
                    user?.userLevel > 0 &&
                    user?.userLevel <= levelBaseImages.length ? (
                    <img
                      src={levelBaseImages[user.userLevel - 1]}
                      alt={`User Level ${user.userLevel}`}
                      className="size-12 object-cover"
                    />
                  ) : (
                    <div className="flex items-center justify-center w-full h-full bg-gray-300">
                      {user?.firstName[0]}
                    </div>
                  )}
                </div>
                <div className="w-full col-span-4">
                  <div className="flex justify-between gap-x-2">
                    <div>{user?.firstName}</div>
                    <div className="text-[#515A70] text-xs text-semibold">
                      {user?.userLevel}/{user?.maxLevel}
                    </div>
                  </div>
                  <League />
                </div>
              </div>
              <div className="col-span-5 w-full flex items-center justify-between">
                <div className="px-2  py-1 flex flex-col justify-center w-full border-r-2 border-[#4E556780]">
                  <div className="text-[10px] font-semibold text-[#515A70] text-center">
                    Shards / Hour
                  </div>
                  <div className="flex items-center justify-center gap-2 mt-1">
                    <img src="./img/time_shard.webp" className="w-5" alt="coin" />
                    <div className="text-sm">
                      {formatNumber(user?.totalIncomePerHour || 0)}
                    </div>
                  </div>
                </div>
                <div className="text-[#C0C9DD] justify-self-end px-2">
                  <Link
                    to={"/settings"}
                    className="flex items-center justify-center p-2 bg-[#273148] text-xl rounded-full self-end"
                  >
                    <FaGear />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div
            className="relative rounded-xl h-full px-6 py-2 flex flex-col items-center gap-2 w-full"
            // style={{
            //   background:
            //     "linear-gradient(180deg, rgba(35, 39, 48, 0.8) 0%, rgba(29, 32, 39, 0.8) 100%)",
            // }}
            style={{
              background: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2)), url(/img/main_page.webp) no-repeat center center fixed`,
              backgroundSize: 'cover',
            }}
          >
            <div className="flex justify-center">
              <DailyOffer />
            </div>
            <div className="flex justify-center items-center gap-4">
              {/* <img src="./img/time_shard.webp" className="w-8" alt="coin" /> */}
              <img src="./img/time_shard.webp" className="w-10" alt="coin" />
              <div className="text-3xl font-extrabold">
                {/* {formatNumber(user?.balance || 0)} */}
                {formatNumberWithCommas(user?.balance || 0)}
              </div>
            </div>
            <div
              className="flex justify-center items-center relative mt-5"
            // style={{
            //   aspectRatio: "1",
            // }}
            >
              <img
                ref={imgRef}
                src={
                  user?.league.avatarUrl ||
                  "https://gamegoat.com/wp-content/uploads/2024/08/6rYkSnMqkPdUS6SzWBD4.png"
                }
                //@ts-ignore
                onTouchStart={handleTouchStart}
                //@ts-ignore
                onTouchMove={handleTouchMove}
                //@ts-ignore
                onTouchEnd={handleTouchEnd}
                //@ts-ignore
                onTouchCancel={handleTouchEnd}
                onContextMenu={handleContextMenu}
                className={`text-lg border-none filter drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2)) px-4 max-h-[calc(100vh/3)]
                ${imageClicked
                    ? "transform scale-90 transition-transform duration-75 ease-in-out"
                    : ""
                  }`}
                alt="egg"
              />
              <img
                src="./img/circles.png"
                className="w-[60%] absolute -z-[1]"
                alt="circles"
              />
            </div>
            <div className=" flex w-full justify-between px-4 items-center">
              <div className="flex items-center gap-2">
                <img src="./img/energy.png" className="w-4" alt="energy" />
                <div className="flex text-base font-extrabold gap-1">
                  <div className="text-[#FE8314]">
                    {user?.energy ? Math.floor(user?.energy) : user?.energy}
                  </div>
                  <div className="text-[#F8AC2B]">/</div>
                  <div className="text-[#F2E96C]">{user?.maxEnergy}</div>
                </div>
              </div>
              <div className="cursor-pointer">
                <BoostButton />
              </div>
            </div>
          </div>
          {numberSignPositions.map((position) => (
            <NumberSign
              key={position.id}
              x={position.x}
              y={position.y}
              id={position.id}
              rewardPerClick={user?.clickPower || 0}
              onAnimationEnd={handleAnimationEnd}
              image="./img/time_shard.webp"
            />
          ))}
        </section>
      )}
    </div>
  );
};

import { useEffect, useRef, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useSoundContext } from './SoundContext';

interface BackgroundSoundProps {
    soundSrc: string;
    volume?: number;
    captionSrc?: string;
    captionLabel?: string;
    captionLang?: string;
}

const BackgroundSound: React.FC<BackgroundSoundProps> = ({
    soundSrc,
    volume = 0.3,
    captionSrc = "",
    captionLabel = "English",
    captionLang = "en"
}) => {
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const location = useLocation();
    const [isPlaying, setIsPlaying] = useState(false);
    const { isSoundEnabled } = useSoundContext();
    const interactionAttemptedRef = useRef(false);

    const attemptPlay = useCallback(() => {
        if (!audioRef.current || isPlaying || !isSoundEnabled) return;

        const isAdminRoute = location.pathname.includes('/admin');
        if (isAdminRoute) return;

        audioRef.current.play()
            .then(() => {
                setIsPlaying(true);
                interactionAttemptedRef.current = true;
            })
            .catch(err => {
                console.log("Waiting for user interaction to play audio");
            });
    }, [isPlaying, isSoundEnabled, location.pathname]);

    const playOnInteraction = useCallback(() => {
        if (!audioRef.current || isPlaying || !isSoundEnabled) return;

        const isAdminRoute = location.pathname.includes('/admin');
        if (isAdminRoute || document.hidden) return;

        audioRef.current.play()
            .then(() => {
                setIsPlaying(true);

                ['click', 'touchstart', 'keydown'].forEach(event => {
                    document.removeEventListener(event, playOnInteraction);
                });
            })
            .catch(err => console.error("Error playing audio:", err));
    }, [isPlaying, isSoundEnabled, location.pathname]);

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.volume = volume;
            audioRef.current.loop = true;
        }
    }, [volume]);

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.hidden && audioRef.current && isPlaying) {
                audioRef.current.pause();
                setIsPlaying(false);
            } else if (!document.hidden && audioRef.current && isSoundEnabled) {
                attemptPlay();
            }
        };

        const handleAppInactive = () => {
            if (audioRef.current && isPlaying) {
                audioRef.current.pause();
                setIsPlaying(false);
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        if (window.Telegram?.WebApp) {
            window.Telegram.WebApp.onEvent('viewportChanged', handleAppInactive);
        }

        window.addEventListener('pagehide', handleAppInactive);
        window.addEventListener('blur', handleAppInactive);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);

            if (window.Telegram?.WebApp) {
                window.Telegram.WebApp.offEvent('viewportChanged', handleAppInactive);
            }

            window.removeEventListener('pagehide', handleAppInactive);
            window.removeEventListener('blur', handleAppInactive);
        };
    }, [isPlaying, isSoundEnabled, attemptPlay]);

    useEffect(() => {
        const isAdminRoute = location.pathname.includes('/admin');

        if (!audioRef.current) return;

        if (isAdminRoute || !isSoundEnabled || document.hidden) {
            audioRef.current.pause();
            setIsPlaying(false);
            return;
        }

        if (!interactionAttemptedRef.current) {
            attemptPlay();
            interactionAttemptedRef.current = true;
        }

        ['click', 'touchstart', 'keydown'].forEach(event => {
            document.addEventListener(event, playOnInteraction);
        });

        return () => {
            ['click', 'touchstart', 'keydown'].forEach(event => {
                document.removeEventListener(event, playOnInteraction);
            });
        };
    }, [location.pathname, soundSrc, isSoundEnabled, isPlaying, attemptPlay, playOnInteraction]);

    useEffect(() => {
        if (!audioRef.current) return;

        if (isSoundEnabled && !isPlaying && !document.hidden) {
            audioRef.current.volume = volume;
            attemptPlay();
        } else if (!isSoundEnabled && isPlaying) {
            audioRef.current.pause();
            setIsPlaying(false);
        }
    }, [isSoundEnabled, volume, isPlaying, attemptPlay]);

    return (
        <audio ref={audioRef} src={soundSrc} preload="auto">
            <track
                kind="captions"
                src={captionSrc}
                srcLang={captionLang}
                label={captionLabel}
            />
            Your browser does not support the audio element.
        </audio>
    );
};

export default BackgroundSound;
import React, { useState, useEffect, useRef } from "react";
import { Task } from "../../models";
import { useUser } from "../../hooks/useUser";

export const TaskModal = ({
  task,
  onClose,
  onOpenLink,
  onCheckStatus,
  isLoading,
}: {
  task: Task;
  onClose: any;
  onOpenLink: any;
  onCheckStatus: any;
  isLoading: boolean;
}) => {
  console.log(task);

  const [visible, setVisible] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const { user } = useUser();

  const getRemainingTime = (rewardAvailableAt: string) => {
    const now = new Date();
    const availableAt = new Date(rewardAvailableAt);
    const timeDifference = availableAt.getTime() - now.getTime();

    if (timeDifference <= 0) return "Reward available now!";

    const minutes = Math.floor((timeDifference % (1000 * 3600)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    return `${minutes}m ${seconds}s`;
  };

  useEffect(() => {
    setVisible(true);

    return () => {
      setVisible(false);
    };
  }, []);

  const handleClose = () => {
    setVisible(false);
    setTimeout(onClose, 300);
  };

  if (!task) return null;

  // Find if the task is in pending rewards
  const pendingReward =
    user && user.pendingRewards.find((reward) => reward.taskId === task._id);
  const remainingTime = pendingReward
    ? getRemainingTime(pendingReward.rewardAvailableAt)
    : null;

  const buttonClass = task.completed
    ? "bg-[#677496] pointer-events-none"
    : "bg-[#288CEE]";

  const buttonText =
    task.type === "telegram"
      ? "Open Link"
      : task.activateUrl.includes("youtube")
        ? "Watch"
        : "Open Link";

  return (
    <>
      <div className="overlay" onClick={handleClose} />
      <div
        id="modal"
        className={`modal ${visible ? "visible" : "hidden"}`}
        ref={modalRef}
      >
        <img className="h-20 w-20 rounded-full" src={task.avatarUrl} />
        <div className="text-xl mt-4 text-center">{task.name}</div>
        <div className="text-xs mb-6 mt-4 text-center text-[#677496]">
          {task.description}
        </div>
        <div className="flex justify-end space-x-2 w-3/4">
          <button
            className={`py-4 px-6 text-sm rounded-2xl flex justify-center items-center ${buttonClass} uppercase w-full`}
            onClick={onOpenLink}
            disabled={task.completed}
          >
            {buttonText}
          </button>
        </div>
        {remainingTime && (
          <div className="mt-4 text-center text-white text-sm">
            Wait {remainingTime} for reward
          </div>
        )}
        <div className="flex flex-column justify-center items-center mt-4">
          <img src="./img/time_shard.webp" className="w-8" />
          <div className="text-2xl ml-2 text-[#E0AE10] font-bold">
            +{task.rewardAmount}
          </div>
        </div>
        {!task.completed && task.type === "telegram" && (
          <button
            className="mt-4 py-4 px-8 text-sm rounded-2xl flex justify-center items-center w-full"
            onClick={onCheckStatus}
          >
            Check
          </button>
        )}

        {isLoading && (
          <div className="loader-overlay">
            <div className="loader-modal" />
          </div>
        )}

        <div className="mb-8" />
      </div>
    </>
  );
};

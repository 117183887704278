export interface BusinessCategory {
  name: string;
  value: string;
}

export const BUSINESS_CATEGORIES: BusinessCategory[] = [
  { name: "Chrono Gadget", value: "chrono_gadget" },
  { name: "Time Wear", value: "time_wear" },
  { name: "Era Tools", value: "era_tools" },
  { name: "Iconic Weapon", value: "iconic_weapon" },
];

import { useEffect, useRef, useState } from "react";
import { Business, User } from "../../models";
import { formatNumber } from "../../utils/formatNumber";

export const BuyBusiness = ({
  business,
  user,
  onBuyBusiness,
  onClose,
}: {
  business: Business;
  user: User | any;
  onBuyBusiness: any;
  onClose: any;
}) => {
  const [visible, setVisible] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setVisible(true);

    return () => {
      setVisible(false);
    };
  }, []);

  const handleClose = () => {
    setVisible(false);
    setTimeout(onClose, 300);
  };
  const isAffordable = (user?.balance || 0) >= business.price;
  const isEnoughRefs = (user?.referrals.length || 0) >= business.refsToUnlock;
  const isLevelMaxed = business.level >= 100;
  const isAvailable = isAffordable && isEnoughRefs && !isLevelMaxed;
  return (
    <>
      <div className="overlay" onClick={handleClose} />
      <div
        id="modal"
        className={`modal ${visible ? "visible" : "hidden"}`}
        ref={modalRef}
      >
        <img src={business?.avatarUrl} className="w-16 h-16 rounded-full" />
        <div className="text-xl mt-6 uppercase">{business?.name}</div>
        <div className="text-sm text-[#677496] px-5 text-center">
          {business?.description}
        </div>
        <div className="mt-6 flex">
          <div className="flex flex-col justify-center items-left">
            <div className="text-sm font-light">Profit per hour</div>
            <div className="flex justify-left items-center">
              <img src="./img/time_shard.webp" className="w-6" />
              <div className="ml-1 text-[#E0AE10]">
                {formatNumber(business?.rewardPerHour)}
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center items-left ml-4">
            <div className="text-sm font-light">Price</div>
            <div className="flex justify-left items-center">
              <img src="./img/time_shard.webp" className="w-6" />
              <div className="ml-1 text-[#E0AE10]">
                {formatNumber(business?.price)}
              </div>
            </div>
          </div>
        </div>
        {!isAffordable && (
          <div className="text-white text-center mt-5">Not enough funds</div>
        )}
        {!isEnoughRefs && isAffordable && (
          <div className="text-white text-center mt-5">
            Not enough refs - {business.refsToUnlock}
          </div>
        )}
        {isLevelMaxed && (
          <div className="text-white text-center mt-5">
            Upgrade locked - Level 100 reached
          </div>
        )}
        {isAvailable && (
          <button
            onClick={() => {
              onBuyBusiness(business?.id);
            }}
            className="mt-4 py-4 px-6 text-sm rounded-lg bg-[#288CEE] w-full mb-8"
          >
            Buy this
          </button>
        )}
      </div>
    </>
  );
};

import React, { useState, useEffect, useContext, useMemo, useRef } from "react";
import { useWebSocket } from "../../hooks/useWebsocket";
import { getTelegramUser } from "../../services/telegramService";
import { useUser } from "../../hooks/useUser";
import { TaskSkeleton } from "./TaskSkeleton";
import { TaskModal } from "./TaskModal";
import { TaskList } from "./TaskList";
import { NotifyContext, NotifyMessage } from "../../contexts/NotifyContext";
import { DataContext } from "../../contexts/DataContext";
import { TopControl } from "../../components/TopControl";
import DailyReward from "../../components/DailyRewardPopUp/DailyReward";
import YoutubeTasks from "./YoutubeTasks";

export const Tasks = () => {
  const { webSocket } = useWebSocket();
  const { user } = useUser();
  const [isDataLoading, setDataLoading] = useState(false);
  const [selectedTask, setSelectedTask] = useState<any>(null);
  const notifyContext = useContext(NotifyContext);
  const dataContext = useContext(DataContext);
  const [showCalendar, setShowCalendar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const [containerHeight, setContainerHeight] = useState<number | undefined>();

  useEffect(() => {
    const adjustHeight = () => {
      const topControl = document.getElementById("top-control");
      const topSection = document.getElementById("top-section");
      const bottomNav = document.getElementById("bottom-nav");
      if (topControl && topSection && bottomNav && containerRef.current) {
        const bottomNavHeight = bottomNav.offsetHeight;
        const topSectionHeight = topSection.offsetHeight;
        const topControlHeight = topControl.offsetHeight;
        const availableHeight =
          window.innerHeight -
          topControlHeight -
          topSectionHeight -
          bottomNavHeight -
          30;
        setContainerHeight(availableHeight);
      }
    };

    adjustHeight();
    window.addEventListener("resize", adjustHeight);

    return () => {
      window.removeEventListener("resize", adjustHeight);
    };
  }, []);

  const dateUnlock = useMemo(() => {
    if (!user || !user.lastDailyRewardTimestamp) {
      return {
        hours: 0,
        minutes: 0,
      };
    }

    let timestamp = user.lastDailyRewardTimestamp;

    if (typeof timestamp === "string") {
      timestamp = new Date(timestamp).getTime();
    }

    const hoursToUnlock = 24 - (Date.now() - timestamp) / 1000 / 60 / 60;
    const roundedHour = Math.max(0, Math.floor(hoursToUnlock));
    const roundedMinute = Math.max(
      0,
      Math.floor((hoursToUnlock - Math.floor(hoursToUnlock)) * 60)
    );

    return {
      hours: roundedHour,
      minutes: roundedMinute,
    };
  }, [user]);

  const getNextRewardAmount = () => {
    const initialReward = dataContext?.settings?.dailyReward || 50;
    const rewardClaimedDays = user?.weeklyClaimedDays || 0;
    const reward = initialReward * Math.pow(2, rewardClaimedDays);
    return reward;
  };

  const dailyReward = [
    {
      rewardAmount: getNextRewardAmount(),
      avatarUrl: "./img/calendar.png",
      name: "DAILY REWARDS",
      description: "None",
      active: true,
      type: "link",
      activateUrl:
        "https://stackoverflow.com/questions/9768444/possible-eventemitter-memory-leak-detected",
      completed: false,
    },
  ];

  useEffect(() => {
    if (webSocket) {
      webSocket.emit("getTasks");

      webSocket.on("taskStatus", (data) => {
        const { id, finished, rewardAvailableAt } = data;

        if (rewardAvailableAt) {
          const countdown = new Date(rewardAvailableAt).getTime() - Date.now();
          setTimeout(() => {
            // Logic to update the UI or notify the user that the reward for this specific task is available
          }, countdown);
        }

        setTimeout(() => {
          dataContext?.setTasks((prevTasks: any) => {
            return prevTasks.map((task: any) =>
              task._id === id ? { ...task, completed: finished } : task
            );
          });

          let notify: NotifyMessage;
          if (finished) {
            setIsLoading(false);
            notify = {
              message: "Task completed! Reward will be available in 1 hour.",
              status: "ok",
              className: "h-96",
            };
          } else {
            setIsLoading(false);
            notify = {
              message: "Task not completed yet.",
              status: "error",
              className: "h-96",
            };
          }
          setSelectedTask(null);
          notifyContext?.setNotify(notify);
        }, 3000);
      });
    }
    return () => {
      webSocket?.off("taskStatus");
    };
  }, [webSocket]);

  const handleTaskClick = (task: any) => {
    setSelectedTask(task);
  };

  const handleModalClose = () => {
    setSelectedTask(null);
  };

  const handleOpenLink = () => {
    if (selectedTask && selectedTask._id) {
      setIsLoading(true);
      const task = dataContext?.tasks.find(
        (task: any) => task._id === selectedTask._id
      );
      const isTaskCompleted = task?.completed;

      if (selectedTask.type !== "telegram" && !isTaskCompleted) {
        const tgUserId = getTelegramUser().id;
        webSocket?.emit(
          "checkTaskStatus",
          JSON.stringify([tgUserId, selectedTask._id])
        );
      }
      window.Telegram.WebApp.openLink(selectedTask.activateUrl);
    }
  };

  const handleCheckStatus = () => {
    const tgUserId = getTelegramUser().id;
    webSocket?.emit(
      "checkTaskStatus",
      JSON.stringify([tgUserId, selectedTask._id])
    );
  };
  return (
    <>
      <TopControl name="Quests" />
      <div className="p-5 rounded-lg max-w-md mx-auto">
        <div className="w-full" id="top-section">
          <div className="w-full flex justify-center items-center mb-3">
            <img
              src="./img/time_shard.webp"
              className="w-[50px] h-[50px]"
              alt="coin"
            />
          </div>
          <p className="text-center text-xs mb-3 font-bold">
            Complete quests to collect time shards and activate
            your time travel!
          </p>
        </div>
        {isDataLoading ? (
          <TaskSkeleton numberOfTasks={5} />
        ) : (
          <div
            ref={containerRef}
            style={{ height: containerHeight }}
            className="task-scrollbar !overflow-y-auto"
          >
            <ul className="list-none p-0 task-scrollbar rounded-lg">
              <div className="text-xs text-center text-white font-normal">
                Game Goat News by Billy
              </div>
              <div className="mt-1 w-full">
                {/* <DailyTask /> */}
                <YoutubeTasks />
              </div>
              <div className="pt-2">
                <h1 className="text-center text-xs font-normal">Daily Tasks</h1>
                {dailyReward.map((task: any, index) => (
                  <li
                    key={index}
                    className="p-1 my-2 bg-[#333A48B2] rounded-2xl flex justify-between items-center shadow-sm"
                    onClick={() => setShowCalendar(!showCalendar)}
                  >
                    <div className="w-full flex flex-row justify-left items-center">
                      <img
                        src={task.avatarUrl}
                        className="mr-2 rounded-2xl w-16 h-16 p-2 object-contain"
                        alt="task-avatar"
                      />
                      <div>
                        <span
                          className={
                            task.completed
                              ? "text-xs font-light	text-gray-500 line-through leading-none"
                              : "text-xs font-light text-white leading-none"
                          }
                        >
                          {task.name}
                        </span>
                        <span className="flex flex-row justify-left ml-2 mt-1 items-center">
                          <div className="flex gap-2 items-center">
                            <div className="size-2 bg-[#E0AE10] rounded-full"></div>
                            <img
                              src="./img/time_shard.webp"
                              className="w-5 h-5"
                              alt="coin"
                            />
                          </div>
                          <div className="ml-1 text-xs text-[#E0AE10] font-bold">
                            +{task.rewardAmount}
                          </div>
                          <div className="text-xs text-[#677496] font-bold ml-1">
                            Billies
                          </div>
                        </span>
                      </div>
                    </div>
                  </li>
                ))}
              </div>
              <div className="text-center text-xs font-normal py-3">
                Tasks List
              </div>
              <TaskList
                tasks={dataContext?.tasks}
                handleTaskClick={handleTaskClick}
                filter="link"
              />
            </ul>
          </div>
        )}
      </div>
      {selectedTask && (
        <TaskModal
          task={selectedTask}
          onClose={handleModalClose}
          onOpenLink={handleOpenLink}
          onCheckStatus={handleCheckStatus}
          isLoading={isLoading}
        />
      )}
      <DailyReward
        dateUnlock={dateUnlock}
        showCalendar={showCalendar}
        setShowCalendar={setShowCalendar}
      />
    </>
  );
};

import { useEffect, useState } from "react";
import { Business } from "../../models";

export const BusinessesFilter = ({
  businesses,
  onCategorySelect,
}: {
  businesses: Business[] | undefined;
  onCategorySelect: (category: string) => void;
}) => {
  const uniqueCategories = [
    //@ts-ignore
    ...new Set(businesses.map((business) => business.category)),
  ];
  const [selectedCategory, setSelectedCategory] = useState<string>(
    uniqueCategories[0]?.toLocaleLowerCase()
  );

  const handleCategoryClick = (category: string) => {
    setSelectedCategory(category);
    onCategorySelect(category);
  };
  useEffect(() => {
    setSelectedCategory(uniqueCategories[0]?.toLocaleLowerCase());
  }, [uniqueCategories[0]]);

  const formatCategoryText = (category: string) => {
    return category
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  return (
    <div className="flex flex-row items-center justify-between w-full bg-[#333A48CC] px-2 py-1 rounded-full mb-2">
      {uniqueCategories.map((category) => (
        <button
          key={category}
          className={`px-3 py-2 font-light text-xs rounded-full uppercase ${selectedCategory === category?.toLocaleLowerCase()
            ? "bg-[#1D1F25B2]"
            : ""
            }`}
          onClick={() => handleCategoryClick(category.toLocaleLowerCase())}
        >
          {formatCategoryText(category)}
        </button>
      ))}
    </div>
  );
};

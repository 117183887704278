import { useEffect, useRef, useState } from 'react';
import { useSoundContext } from '../components/Sounds/SoundContext';

/**
 * Hook to manage click sound functionality
 * @param soundPath Path to the sound file
 * @returns Object with playSound function and audio state
 */
export const useClickSound = (soundPath: string = '/sounds/tap_sound.mp3') => {
    const { isSoundEnabled } = useSoundContext();
    const soundRef = useRef<HTMLAudioElement | null>(null);
    const [isAudioReady, setIsAudioReady] = useState(false);

    useEffect(() => {
        if (!soundRef.current) {
            soundRef.current = new Audio(soundPath);
            soundRef.current.addEventListener('canplaythrough', () => {
                setIsAudioReady(true);
            });
            soundRef.current.load();
        }
        const unlockAudio = () => {
            if (soundRef.current && isSoundEnabled) {
                soundRef.current.volume = 0;
                soundRef.current.play()
                    .then(() => {
                        soundRef.current!.pause();
                        soundRef.current!.volume = 1.0;
                        setIsAudioReady(true);
                    })
                    .catch(err => {
                        console.log("Audio not yet unlocked, waiting for user interaction");
                    });
            }
        };
        unlockAudio();
        const events = ['click', 'touchstart', 'keydown'];
        const handleFirstInteraction = () => {
            unlockAudio();
            events.forEach(event => {
                document.removeEventListener(event, handleFirstInteraction);
            });
        };

        events.forEach(event => {
            document.addEventListener(event, handleFirstInteraction);
        });
        return () => {
            events.forEach(event => {
                document.removeEventListener(event, handleFirstInteraction);
            });
        };
    }, [isSoundEnabled, soundPath]);

    const playSound = () => {
        if (isSoundEnabled && soundRef.current && isAudioReady) {
            soundRef.current.currentTime = 0;
            const playPromise = soundRef.current.play();
            if (playPromise !== undefined) {
                playPromise.catch(error => {
                    console.error("Error playing sound:", error);
                    if (!isAudioReady) {
                        soundRef.current!.volume = 0;
                        soundRef.current!.play()
                            .then(() => {
                                soundRef.current!.pause();
                                soundRef.current!.volume = 1.0;
                                setIsAudioReady(true);
                                playSound();
                            })
                            .catch(err => {
                                console.log("Audio playback still blocked");
                            });
                    }
                });
            }
        }
    };

    return { playSound, isAudioReady };
};
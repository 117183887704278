import React, { useEffect, useState } from "react";
import { getConfig } from "../../../utils/config";
import { User } from "../../../models";
import CsvExporter from "./CsvExporter";
import EditModal from "./EditModal";
import DeleteUser from "./DeleteUser";
import { formatNumber } from "../../../utils/formatNumber";

const { adminApiUrl } = getConfig();

const TAKE = 10;

export const AdminUsers = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [editingBalance, setEditingBalance] = useState<{
    [key: number]: number | undefined;
  }>({});
  const [modalUser, setModalUser] = useState<User | null>(null);

  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [sortField, setSortField] = useState<string>("balance");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");

  const refreshUsers = async () => {
    const skip = (page - 1) * TAKE;

    const response = await fetch(
      `${adminApiUrl}/admin/users?skip=${skip}&take=${TAKE}&sortField=${sortField}&sortOrder=${sortOrder}`,
      { headers: { "Admin-Token": localStorage.getItem("password") || "" } }
    );
    const data = await response.json();
    setUsers(data.data);
    setTotal(data.total);
    setSelectedUsers([]);
  };

  const onNextClick = () => {
    if (page < Math.ceil(total / TAKE)) {
      setPage(page + 1);
    }
  };

  const onPrevClick = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleSortChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const [field, order] = e.target.value.split("_");
    setSortField(field);
    setSortOrder(order as "asc" | "desc");
  };

  useEffect(() => {
    refreshUsers();
  }, [page, sortField, sortOrder]);

  const resetUsers = async () => {
    if (!window.confirm("Are you sure?")) return;
    const response = await fetch(`${adminApiUrl}/admin/reset-users`, {
      method: "GET",
      headers: {
        "Admin-Token": localStorage.getItem("password") || "",
      },
    });

    if (response.ok) {
      setIsError(false);
      setIsSuccess(true);
      refreshUsers();
    } else {
      setIsSuccess(false);
      setIsError(true);
    }
  };

  const handleEdit = (user: User) => {
    setModalUser(user);
    setEditingBalance({ ...editingBalance, [user.tgId]: user.balance });
  };

  const handleSelectUser = (user: User) => {
    if (selectedUsers.some((u) => u.tgId === user.tgId)) {
      setSelectedUsers(selectedUsers.filter((u) => u.tgId !== user.tgId));
    } else {
      setSelectedUsers([...selectedUsers, user]);
    }
  };

  return (
    <div className="flex flex-col space-y-4 text-black">
      <div className="flex flex-row items-start justify-between mb-5">
        <div className="text-white">Users - {total}</div>
        <CsvExporter selectedUsers={selectedUsers} />
      </div>
      <div className="flex flex-row items-start justify-between">
        <select
          className="px-4 py-2 bg-slate-50 rounded"
          onChange={handleSortChange}
          value={`${sortField}_${sortOrder}`}
        >
          <option value="balance_asc">Balance (Asc)</option>
          <option value="balance_desc">Balance (Desc)</option>
          <option value="firstName_asc">Name (Asc)</option>
          <option value="firstName_desc">Name (Desc)</option>
          <option value="createdAt_asc">Date (Asc)</option>
          <option value="createdAt_desc">Date (Desc)</option>
        </select>

        <div className="flex flex-row items-center space-x-2">
          <button
            className="py-2 px-4 bg-slate-50 hover:bg-slate-300 font-mono text-sm rounded"
            onClick={onPrevClick}
          >
            Prev
          </button>

          <span className="py-2 px-4 bg-slate-50 font-mono text-sm rounded">
            {(page - 1) * TAKE + 1} - {(page - 1) * TAKE + users.length}
          </span>

          <button
            className="py-2 px-4 bg-slate-50 hover:bg-slate-300 font-mono text-sm rounded"
            onClick={onNextClick}
          >
            Next
          </button>
        </div>
      </div>
      <div className="overflow-x-scroll rounded">
        <table className="table-auto w-full bg-slate-50 rounded">
          <thead className="bg-slate-200 sticky top-0">
            <tr>
              <th className="px-4 py-2 text-left">
                <input
                  type="checkbox"
                  checked={
                    selectedUsers.length === users.length && users.length > 0
                  }
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedUsers(users);
                    } else {
                      setSelectedUsers([]);
                    }
                  }}
                />
              </th>
              <th className="px-4 py-2 text-left">#</th>
              <th className="px-4 py-2 text-left">Username</th>
              <th className="px-4 py-2 text-left">Telegram ID</th>
              <th className="px-4 py-2 text-left">Balance</th>
              <th className="px-4 py-2 text-left">Referrals</th>
              <th className="px-4 py-2 text-left"></th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={user.tgId} className="border-b">
                <td className="px-4 py-2">
                  <input
                    type="checkbox"
                    checked={selectedUsers.some((u) => u.tgId === user.tgId)}
                    onChange={() => handleSelectUser(user)}
                  />
                </td>
                <td className="px-4 py-2">{index + 1 + (page - 1) * TAKE}</td>
                <td className="px-4 py-2">
                  {user.tgUsername || user.firstName}
                  <div>
                    {user.referredBy && (
                      <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800">
                        <svg className="mr-1 h-2 w-2 text-gray-500" fill="currentColor" viewBox="0 0 8 8">
                          <circle cx="4" cy="4" r="3" />
                        </svg>
                        Ref: {user.referredBy.tgUsername || user.referredBy.firstName}
                      </span>
                    )}
                  </div>
                </td>
                <td className="px-4 py-2">{user.tgId}</td>
                <td className="px-4 py-2">{formatNumber(user.balance)}</td>
                <td className="px-4 py-2">{user.referrals.length}</td>
                <td className="px-4 py-2 flex gap-1 items-center">
                  <button
                    onClick={() => handleEdit(user)}
                    className="bg-green-500 text-white px-2 py-1 rounded hover:bg-green-600"
                  >
                    Edit
                  </button>
                  <DeleteUser userId={user._id} refreshUsers={refreshUsers} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {modalUser && (
        <EditModal
          modalUser={modalUser}
          setModalUser={setModalUser}
          editingBalance={editingBalance}
          setEditingBalance={setEditingBalance}
          refreshUsers={refreshUsers}
          setIsError={setIsError}
          setIsSuccess={setIsSuccess}
        />
      )}

      <div className="flex flex-col mb-4">
        <h2 className="text-white">Reset users:</h2>
        <button
          className="bg-red-600 hover:bg-red-700 text-white py-2 px-4 w-full font-mono font-bold rounded"
          onClick={resetUsers}
        >
          Reset users
        </button>
      </div>

      {isSuccess && (
        <div className="bg-green-400 text-center text-white">
          Settings saved
        </div>
      )}
      {isError && (
        <div className="bg-red-600 text-center text-white">Error</div>
      )}
    </div>
  );
};

import { memo } from "react";
import { Business, User } from "../../models";
import { formatNumber } from "../../utils/formatNumber";

export const BusinessesList = memo(function BusinessesList({
  businesses,
  user,
  setSelectedBusiness,
  setModalOpen,
  loadingBusinessIds,
}: {
  businesses: Business[];
  user: User;
  setSelectedBusiness: (arg0: Business) => void;
  setModalOpen: (arg0: boolean) => void;
  loadingBusinessIds: string[];
}) {
  return (
    <>
      {businesses.map((business) => {
        const isLoading = loadingBusinessIds.includes(business.id);

        return (
          <div
            key={business.id}
            className={`business-item relative`}
            onClick={() => {
              setSelectedBusiness(business);
              setModalOpen(true);
            }}
          >
            {isLoading && (
              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-80 text-white text-center rounded-2xl">
                <div className="loader"></div>
              </div>
            )}

            <div className="flex justify-left p-2 pb-2">
              <img
                src={business.avatarUrl}
                className="rounded-lg w-16 h-14 object-cover"
              />
              <div className="pl-2 flex flex-col h-full justify-between">
                <h3 className="break-all text-[10px] font-bold">
                  {business.name}
                </h3>
                <div className="text-[10px] text-[#677496] pb-1 font-bold">
                  Profit per hour:
                </div>

                <div className="flex items-center gap-1">
                  <img src="./img/time_shard.webp" className="w-4 h-4" />
                  <div className="text-xs font-bold">
                    {formatNumber(business.rewardPerHour)}
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full h-10 rounded-xl flex items-center justify-evenly px-2">
              <div className="text-sm text-[#677496] font-extrabold">
                Lvl.{business.level}
              </div>
              <div className="flex items-center">
                <img src="./img/time_shard.webp" className="w-5 h-5" />
                <div className="ml-1 text-sm text-[#E0AE10] font-extrabold">
                  {formatNumber(business.price)}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
});

import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import { useUser } from '../../hooks/useUser';

interface SoundContextType {
    isSoundEnabled: boolean;
    setIsSoundEnabled: (value: boolean) => void;
    toggleSound: () => void;
    isFetching: boolean;
}

const SoundContext = createContext<SoundContextType | null>(null);

export const useSoundContext = () => {
    const context = useContext(SoundContext);
    if (!context) {
        throw new Error('useSoundContext must be used within a SoundProvider');
    }
    return context;
};

export const SoundProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { user } = useUser();
    const [isSoundEnabled, setIsSoundEnabled] = useState<boolean>(false);
    const [isFetching, setIsFetching] = useState<boolean>(false);

    useEffect(() => {
        if (user?.id) {
            fetchSoundPreference(user.id);
        }
    }, []);

    const fetchSoundPreference = async (userId: string) => {
        setIsFetching(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_ADMIN_API_URL}/users/sound-preference/${userId}`);
            const data = await response.json();

            if (data.status === 200 && data.isSoundEnabled !== undefined) {
                setIsSoundEnabled(data.isSoundEnabled);
            }
        } catch (error) {
            console.error("Error fetching sound preference:", error);
        } finally {
            setIsFetching(false);
        }
    };

    const toggleSound = async () => {
        const newValue = !isSoundEnabled;
        setIsSoundEnabled(newValue);

        if (user?.id) {
            setIsFetching(true);
            try {
                const response = await fetch(`${process.env.REACT_APP_ADMIN_API_URL}/users/sound-preference/${user.id}`, {
                    method: "PATCH",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ isSoundEnabled: newValue }),
                });

                if (response.ok) {
                    await fetchSoundPreference(user.id);
                } else {
                    console.error("Failed to update sound preference on server");
                    setIsSoundEnabled(!newValue);
                }
            } catch (error) {
                console.error("Error saving sound preference:", error);
                setIsSoundEnabled(!newValue);
            } finally {
                setIsFetching(false);
            }
        }
    };

    const contextValue = useMemo(() => ({
        isSoundEnabled,
        setIsSoundEnabled,
        toggleSound,
        isFetching
    }), [isSoundEnabled, isFetching]);

    return (
        <SoundContext.Provider value={contextValue}>
            {children}
        </SoundContext.Provider>
    );
};